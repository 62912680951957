@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Noto Sans";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans"),
		url("../fonts/Noto_Sans/NotoSans-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "Noto Sans Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Italic"),
		url("../fonts/Noto_Sans/NotoSans-Italic.ttf") format("truetype");
}

@font-face {
	font-family: "Noto Sans Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Bold"),
		url("../fonts/Noto_Sans/NotoSans-Bold.ttf") format("truetype");
}
@font-face {
	font-family: "Noto Sans Extra Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Extra Bold"),
		url("../fonts/Noto_Sans/NotoSans-ExtraBold.ttf") format("truetype");
}
@font-face {
	font-family: "Noto Sans Extra Bold Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Extra Bold Italic"),
		url("../fonts/Noto_Sans/NotoSans-ExtraBoldItalic.ttf") format("truetype");
}

@font-face {
	font-family: "Noto Sans Extra Light Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Extra Light Italic"),
		url("../fonts/Noto_Sans/NotoSans-ExtraLightItalic.ttf") format("truetype");
}
@font-face {
	font-family: "Noto Sans Semi Bold";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Semi Bold"),
		url("../fonts/Noto_Sans/NotoSans-SemiBold.ttf") format("truetype");
}
@font-face {
	font-family: "Noto Sans Semi Bold Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Semi Bold Italic"),
		url("../fonts/Noto_Sans/NotoSans-SemiBoldItalic.ttf") format("truetype");
}
@font-face {
	font-family: "Noto Sans Light";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Light"),
		url("../fonts/Noto_Sans/NotoSans-Light.ttf") format("truetype");
}
@font-face {
	font-family: "Noto Sans Light Italic";
	font-style: normal;
	font-weight: normal;
	src: local("Noto Sans Light Italic"),
		url("../fonts/Noto_Sans/NotoSans-LightItalic.ttf") format("truetype");
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Noto Sans", "Segoe UI",
		"Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

html::-webkit-scrollbar {
	display: none;
}

html {
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.transition_cus {
	transition: all 0.25s ease-in-out;
}

/* ================ SCROLL BAR CSS =============== */

::-webkit-scrollbar {
	width: 7px;
	height: 5px;
}

::-webkit-scrollbar-track {
	background: rgba(128, 126, 126, 0.411);
	border-radius: 99999px;
}

::-webkit-scrollbar-thumb {
	background: #46444446;
	border-radius: 99999px;
	cursor: pointer;
}

::-webkit-scrollbar-thumb:hover {
	background: #1f1f1f80;
	transition: linear 300ms;
}

/* ================ CUSTOM SELECT OPTIONS ================== */
.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8 {
	border-radius: 15px;
}

.MuiFormControl-root .MuiBox-root svg {
	stroke: white;
}

.res_text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* ============== ANIMATION MIC ================ */
.mic {
	position: relative;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	width: 68px;
	height: 68px;
	background: linear-gradient(to bottom, #7340d8 30%, #48d8e4 90%);
	color: #fff;
	font-size: 33px;
	border: none;
	border-radius: 50%;
	cursor: pointer;
	transition: background-color 0.25s;
}

.mic::before {
	content: "";
	position: absolute;
	width: inherit;
	height: inherit;
	border-radius: inherit;
	background: linear-gradient(to bottom, #7340d8 30%, #48d8e4 90%);
	z-index: 1;
}

.listening {
	background: linear-gradient(to bottom, #7340d8 30%, #48d8e4 90%);
}

.listening::before {
	animation: listening 1.3s infinite;
}

@keyframes listening {
	from {
		opacity: 0.25;
	}

	to {
		transform: scale(2);
		opacity: 0;
	}
}

/* ============= CSS Loading Chat=============*/
.loader_chat {
	width: 3.5em;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
}

.loader_chat span {
	width: 0.6em;
	height: 0.6em;
	border-radius: 50%;
	background-color: #008cff;
}

.loader_chat span:nth-of-type(1) {
	transform: translateX(-100%);
	animation: left-swing 0.5s ease-in alternate infinite;
}

.loader_chat span:nth-of-type(3) {
	transform: translateX(-95%);
	animation: right-swing 0.5s ease-out alternate infinite;
}

@keyframes left-swing {
	50%,
	100% {
		transform: translateX(95%);
	}
}

@keyframes right-swing {
	50% {
		transform: translateX(-95%);
	}

	100% {
		transform: translateX(100%);
	}
}

.apexcharts-tooltip {
	color: #000 !important;
}

.apexcharts-menu {
	color: #000 !important;
}
