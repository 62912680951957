.rdr-buttons-position {
	bottom: 1rem;
}

.rdrDefinedRangesWrapper {
	display: none;
}

.rdrDateDisplayItemActive {
	padding: 1px;
	border: 1px solid rgb(169, 49, 159);
}

.rdrCalendarWrapper {
	background-color: rgba(102, 102, 102, 1);
	backdrop-filter: blur(30px);
	color: #ffffff;
	border-radius: 12px;
}

.rdrDateDisplayWrapper {
	background-color: rgba(102, 102, 102, 1);
	backdrop-filter: blur(16px);
	color: #ffffff;
	border-top-right-radius: 12px;
	border-top-left-radius: 12px;
}

.rdrDayNumber span {
	color: #ffffff;
}

.rdrMonthPicker select,
.rdrMonthName,
.rdrYearPicker select {
	font-size: 14px;
	color: #ffffff;
}

.rdrMonthPicker select option,
.rdrYearPicker select option {
	font-size: 14px;
	color: #000000;
}

.rdrDayPassive {
	opacity: 0.5 !important;
}

.rdrDayToday .rdrDayNumber span:after {
	background: rgb(169, 49, 159) !important;
}
